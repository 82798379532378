// Packages
import { Helmet } from 'react-helmet'

export default function Seo(data) {

  const description = data?.description || 'Cold email software that is easy to use, with automated follow-ups when there is no response. Customizable outbound email tracking software for sales.';
  const title = data?.title || 'QuickMail - Send Emails. Get Replies.';
  const metaImage = data?.image || '/assets/thumbnail.png';
  const urlWithBeginningSlash = data?.url?.startsWith('/') ? data?.url : '/' + data?.url;
  const url = urlWithBeginningSlash === '/' ? '' : urlWithBeginningSlash;

  return (
    <Helmet>
      <meta property="og:locale" content="en" />

      {data?.noindex && <meta name="robots" content="noindex" />}

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />

      <meta property="og:site_name" content="QuickMail" />
      <meta property="twitter:card" content="summary_large_image" />

      {(url !== null && url !== undefined) && <link rel="canonical" href={`https://quickmail.com${url}`} /> }
      {(url !== null && url !== undefined) && <meta property="og:url" content={`https://quickmail.com${url}`} /> }
      {(url !== null && url !== undefined) && <meta property="twitter:url" content={`https://quickmail.com${url}`} /> }

      <meta property="og:type" content="website" />

      <meta property="og:image" content={metaImage} />
      <meta property="twitter:image" content={metaImage} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />

      {data?.children}

    </Helmet>
  )
}
