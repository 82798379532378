import React from 'react'

import '../../static/styles/hero.css'

export default ({ children, img }) => (
  <div className="dark-blue-bg diagonal-group blue-diagonal-after">
    <div className="container padded">
      <section className="row generic-hero" id="hero">
        {img ? (
          <>
            <div className="col-12 col-md-6 align-self-center">{children}</div>
            <div className="col-12 col-md-6 align-self-end">
              <div className="illustration-container d-flex align-items-end">
                {img}
              </div>
            </div>
          </>
        ) : (
          <div className="col-12">{children}</div>
        )}
      </section>
    </div>
  </div>
)
